<template>
  <VContainer
    v-if="preparedTask"
    class="pa-0"
  >
    <VRow dense>
      <VCol>
        <div
          class="tt-text-headline-1"
          data-test="questionnaire-event-title"
        >
          {{ event.task.name }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-for="(content) in preparedTask.content"
        :id="`item-${content.id}`"
        :key="content.id"
        :md="$options.TASK_COLS"
        cols="12"
        sm="12"
      >
        <HTMLItem
          v-if="content.type === $options.ITEM_TYPES.HTML"
          :meta="content.meta"
          data-test="html-item"
        />

        <CardWrapper
          v-else-if="content.type === $options.ITEM_TYPES.DOCUMENT"
          flat
          class="fill-height pa-5"
          corner-cut
          shadow="never"
        >
          <div
            v-if="content.itemStatus"
            class="pb-2"
          >
            <ChipStatus :status="shouldShowStatus(content.itemStatus)" />
          </div>
          <DocumentItem
            :meta="content.meta"
            :result="!!content.result"
            :can-be-skipped="content.canBeSkipped"
            :data-test="`document-item-${content.id}`"
            @submit="submit(true, content)"
          />
        </CardWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.CONTACT"
        >
          <ContactsItem
            :data-test="`contacts-item-${content.id}`"
            :meta="content.meta"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.RATE"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <RateItem
            :meta="content.meta"
            :result="content.result"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :data-test="`rate-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.ADD_PHOTO"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <ImageFormItem
            :meta="content.meta"
            :is-skipped="content.isSkipped"
            :error-messages="getErrorMessages(content.id)"
            :is-item-completed="content.isCompleted"
            :can-be-skipped="content.canBeSkipped"
            :task-completed="event.task.isCompleted"
            :data-test="`image-form-item-${content.id}`"
            @submit="({payload,callback}) => submit(payload, content,callback)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.YES_NO"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <YesOrNoItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`yes-or-no-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.SELECT_ONE"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <QuestionItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`question-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.SELECT_MANY"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <QuestionMultipleItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`question-multiple-select-many-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.VOTE"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <QuestionMultipleItem
            v-if="content.meta.is_multi"
            is-vote
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`question-multiple-vote-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
          <QuestionItem
            v-else
            is-vote
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`question-vote-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="
            (content.type === $options.ITEM_TYPES.ONE_WORD) || (content.type === $options.ITEM_TYPES.QR_CODE)
          "
          :status="shouldShowStatus(content.itemStatus)"
        >
          <WordItem
            :is-q-r="content.type === $options.ITEM_TYPES.QR_CODE"
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :data-test="`word-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <MapItem
          v-else-if="content.type === $options.ITEM_TYPES.MAP"
          :meta="content.meta"
          :result="content.result"
          :can-be-skipped="content.canBeSkipped"
          :is-skipped="content.isSkipped"
          :disabled="loading===content.id || isTaskExpired"
          :loading="loading===content.id"
          :data-test="`map-item-${content.id}`"
          @submit="submit($event, content)"
          @skip="skip(content.id)"
        />

        <VideoItem
          v-else-if="content.type === $options.ITEM_TYPES.VIDEO"
          :meta="content.meta"
          :result="!!content.result"
          :data-test="`video-item-${content.id}`"
          @submit="submit(true, content)"
        />

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.ENTER_STRING"
          :status="shouldShowStatus(content.itemStatus)"
        >
          <TextAnswerItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id || isTaskExpired"
            :loading="loading===content.id"
            :task-completed="event.task.isCompleted"
            :data-test="`text-answer-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>
        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.IMAGE"
          class="pa-0"
        >
          <ImageTaskItem
            :data-test="`image-task-item-${content.id}`"
            :meta="content.meta"
          />
        </ItemWrapper>
        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.OFFER"
        >
          <OfferItem
            :meta="content.meta"
            :data-test="`offer-item-${content.id}`"
          />
        </ItemWrapper>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTBtn
          :disabled="!canBeCompleted"
          :loading="completeButtonLoading"
          data-test="button-done"
          @click="onComplete"
        >
          {{ $t('button_text.done') }}
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import HTMLItem from '@/components/items/HTMLItem.vue';
import ItemWrapper from '@/components/task/ItemWrapper.vue';
import DocumentItem from '@/components/items/DocumentItem.vue';
import ContactsItem from '@/components/items/ContactsItem.vue';
import RateItem from '@/components/items/RateItem.vue';
import ImageFormItem from '@/components/items/ImageFormItem.vue';
import YesOrNoItem from '@/components/items/YesOrNoItem.vue';
import QuestionItem from '@/components/items/QuestionItem.vue';
import QuestionMultipleItem from '@/components/items/QuestionMultipleItem.vue';
import MapItem from '@/components/items/MapItem.vue';
import VideoItem from '@/components/items/VideoItem.vue';
import TextAnswerItem from '@/components/items/TextAnswerItem.vue';
import ImageTaskItem from '@/components/items/ImageTaskItem.vue';
import OfferItem from '@/components/items/OfferItem.vue';
import WordItem from '@/components/items/WordItem.vue';
import { entityType, ITEM_TYPES } from '@/helpers/constants';
import getResultRequest from '@/helpers/getResultRequest';
import * as snamiApi from '@/services/api/snami';
import { generateFlatItem } from '@/helpers/generateFlatItem';
import { mapActions, mapGetters } from 'vuex';
import { SKIP_ITEM_ACTION } from '@/plugins/vuex/actionTypes';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'QuestionnaireEvent',
  ITEM_TYPES,
  components: {
    CardWrapper,
    ChipStatus,
    WordItem,
    OfferItem,
    ImageTaskItem,
    TextAnswerItem,
    VideoItem,
    MapItem,
    QuestionMultipleItem,
    QuestionItem,
    YesOrNoItem,
    ImageFormItem,
    RateItem,
    ContactsItem,
    DocumentItem,
    ItemWrapper,
    HTMLItem,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    completeButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
      preparedTask: null,
    };
  },
  computed: {
    ...mapGetters('levels', { skipped: 'skippedTaskItem', levelById: 'levelById' }),
    canBeCompleted() {
      const { preparedTask } = this;
      if (!preparedTask || !preparedTask.content || preparedTask.isCompleted || this.isTaskExpired) {
        return false;
      }
      return preparedTask.content.every((item) => item.isSkipped || item.isCompleted
        || !item.canHasResult || (item.canBeSkipped && item.type === ITEM_TYPES.DOCUMENT));
    },
    isTaskExpired() {
      return this.event.expired;
    },
  },
  watch: {
    event: {
      immediate: true,
      handler() {
        this.preparedTask = this.prepareTask(this.event.task);
      },
    },
  },
  methods: {
    ...mapActions('levels', { skipItemAction: SKIP_ITEM_ACTION }),

    skip(id) {
      this.skipItemAction(id);
      this.preparedTask = this.prepareTask(this.event.task);
    },
    shouldShowStatus(itemStatus) {
      if (this.isTaskExpired) return false;
      return itemStatus;
    },
    onComplete() {
      this.$emit('complete');
    },
    prepareTask(task) {
      const taskId = task.id;
      const currentTask = task;
      if (currentTask && currentTask.content) {
        const { isCompleted: taskCompleted } = currentTask;
        const content = currentTask.content.map(
          (taskItem) => generateFlatItem(
            {
              skippedArray: this.skipped,
              taskId,
              levelId: undefined,
              taskCompleted,
              taskItem,
            },
          ),
        );
        return { ...currentTask, content };
      }
      return null;
    },
    async submit(answer, item, callback = null) {
      this.clearErrors(item.id);
      if (item.type === ITEM_TYPES.ENTER_STRING && item.canBeSkipped && !answer) {
        this.skip(item.id);
        return;
      }
      try {
        this.loading = item.id;
        const data = getResultRequest(item, answer, entityType.EVENT);
        const { data: result } = await snamiApi.taskResultUpdate({ data });
        const mergedTask = {
          ...this.preparedTask,
          ...result[0].tasks[0],
          content: this.preparedTask.content.map((content) => {
            const findedById = result[0].tasks[0].content.findIndex((newContent) => newContent.id === content.id);
            if (findedById !== -1) {
              return result[0].tasks[0].content[findedById];
            }
            return content;
          }),
        };
        this.preparedTask = this.prepareTask(mergedTask);
      } catch (e) {
        const dataError = e.response?.data?.error;
        if (dataError) {
          this.errors.push({ id: item.id, dataError });
        }
      } finally {
        if (callback) {
          callback();
        }
        this.loading = null;
      }
    },
    clearErrors(itemId) {
      this.errors = this.errors.filter((errorItem) => errorItem.id !== itemId);
    },
  },
};
</script>

<style scoped>

</style>
