<template>
  <VContainer class="pa-0">
    <VRow dense>
      <VCol>
        <div class="tt-text-headline-1">
          {{ event.task.name }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        v-for="(content) in event.task.content"
        :id="`item-${content.id}`"
        :key="content.id"
        :md="$options.TASK_COLS"
        cols="12"
        sm="12"
      >
        <HTMLItem
          v-if="content.type === $options.ITEM_TYPES.HTML"
          :meta="content.meta"
          data-test="level-html-item"
        />

        <CardWrapper
          v-else-if="content.type === $options.ITEM_TYPES.DOCUMENT"
          flat
          class="fill-height pa-5"
          corner-cut
          shadow="never"
        >
          <div
            v-if="content.itemStatus"
            class="pb-2"
          >
            <ChipStatus :status="content.itemStatus" />
          </div>
          <DocumentItem
            :meta="content.meta"
            :result="!!content.result"
            :can-be-skipped="content.canBeSkipped"
            :data-test="`level-document-item-${content.id}`"
            @submit="submit(true, content)"
          />
        </CardWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.CONTACT"
        >
          <ContactsItem
            :data-test="`level-contacts-item-${content.id}`"
            :meta="content.meta"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.RATE"
          :status="content.itemStatus"
        >
          <RateItem
            :meta="content.meta"
            :result="content.result"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :data-test="`level-rate-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.ADD_PHOTO"
          :status="content.itemStatus"
        >
          <ImageFormItem
            :meta="content.meta"
            :is-skipped="content.isSkipped"
            :error-messages="getErrorMessages(content.id)"
            :is-item-completed="content.isCompleted"
            :can-be-skipped="content.canBeSkipped"
            :task-completed="task.isCompleted"
            :data-test="`level-image-form-item-${content.id}`"
            @submit="({payload,callback}) => submit(payload, content,callback)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.YES_NO"
          :status="content.itemStatus"
        >
          <YesOrNoItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-yes-or-no-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.SELECT_ONE"
          :status="content.itemStatus"
        >
          <QuestionItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-question-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.SELECT_MANY"
          :status="content.itemStatus"
        >
          <QuestionMultipleItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-question-multiple-select-many-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.VOTE"
          :status="content.itemStatus"
        >
          <QuestionMultipleItem
            v-if="content.meta.is_multi"
            is-vote
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-question-multiple-vote-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
          <QuestionItem
            v-else
            is-vote
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-question-vote-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <ItemWrapper
          v-else-if="
            (content.type === $options.ITEM_TYPES.ONE_WORD) || (content.type === $options.ITEM_TYPES.QR_CODE)
          "
          :status="content.itemStatus"
        >
          <WordItem
            :is-q-r="content.type === $options.ITEM_TYPES.QR_CODE"
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :data-test="`level-word-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>

        <MapItem
          v-else-if="content.type === $options.ITEM_TYPES.MAP"
          :meta="content.meta"
          :result="content.result"
          :can-be-skipped="content.canBeSkipped"
          :is-skipped="content.isSkipped"
          :disabled="loading===content.id"
          :loading="loading===content.id"
          :data-test="`level-map-item-${content.id}`"
          @submit="submit($event, content)"
          @skip="skip(content.id)"
        />

        <VideoItem
          v-else-if="content.type === $options.ITEM_TYPES.VIDEO"
          :meta="content.meta"
          :result="!!content.result"
          :data-test="`level-video-item-${content.id}`"
          @submit="submit(true, content)"
        />

        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.ENTER_STRING"
          :status="content.itemStatus"
        >
          <TextAnswerItem
            :meta="content.meta"
            :result="content.result"
            :can-be-skipped="content.canBeSkipped"
            :is-skipped="content.isSkipped"
            :disabled="loading===content.id"
            :loading="loading===content.id"
            :task-completed="task.isCompleted"
            :data-test="`level-text-answer-item-${content.id}`"
            @submit="submit($event, content)"
            @skip="skip(content.id)"
          />
        </ItemWrapper>
        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.IMAGE"
          class="pa-0"
        >
          <ImageTaskItem
            :data-test="`level-image-task-item-${content.id}`"
            :meta="content.meta"
          />
        </ItemWrapper>
        <ItemWrapper
          v-else-if="content.type === $options.ITEM_TYPES.OFFER"
        >
          <OfferItem
            :meta="content.meta"
            :data-test="`level-offer-item-${content.id}`"
          />
        </ItemWrapper>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTBtn
          :disabled="isExpired || isCompleted"
          :loading="completeButtonLoading"
          @click="onComplete"
        >
          {{ $t('button_text.done') }}
        </TTBtn>
      </VCol>
    </VRow>
  </vcontainer>
</template>

<script>
import WordItem from '@/components/items/WordItem.vue';
import OfferItem from '@/components/items/OfferItem.vue';
import ImageTaskItem from '@/components/items/ImageTaskItem.vue';
import TextAnswerItem from '@/components/items/TextAnswerItem.vue';
import VideoItem from '@/components/items/VideoItem.vue';
import MapItem from '@/components/items/MapItem.vue';
import QuestionMultipleItem from '@/components/items/QuestionMultipleItem.vue';
import QuestionItem from '@/components/items/QuestionItem.vue';
import YesOrNoItem from '@/components/items/YesOrNoItem.vue';
import ImageFormItem from '@/components/items/ImageFormItem.vue';
import RateItem from '@/components/items/RateItem.vue';
import ContactsItem from '@/components/items/ContactsItem.vue';
import DocumentItem from '@/components/items/DocumentItem.vue';
import ItemWrapper from '@/components/task/ItemWrapper.vue';
import HTMLItem from '@/components/items/HTMLItem.vue';
import { ITEM_TYPES } from '@/helpers/constants';
import ChipStatus from '@/components/chips/ChipStatus.vue';
import CardWrapper from '@/components/cards/CardWrapper.vue';

export default {
  name: 'CheckpointEvent',
  ITEM_TYPES,
  components: {
    CardWrapper,
    ChipStatus,
    WordItem,
    OfferItem,
    ImageTaskItem,
    TextAnswerItem,
    VideoItem,
    MapItem,
    QuestionMultipleItem,
    QuestionItem,
    YesOrNoItem,
    ImageFormItem,
    RateItem,
    ContactsItem,
    DocumentItem,
    ItemWrapper,
    HTMLItem,
  },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    completeButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExpired() {
      return this.event.expired;
    },
    isCompleted() {
      return this.event.task.isCompleted;
    },
  },
  methods: {
    onComplete() {
      this.$emit('complete');
    },
  },
};
</script>

<style scoped>

</style>
