<template>
  <VContainer class="pa-0">
    <VRow v-if="answer">
      <VCol
        cols="12"
      >
        <HTMLItem
          :meta="answer.meta"
          data-test="bug-event-answer"
        />
      </VCol>
    </VRow>
    <VRow v-if="report">
      <VCol
        cols="12"
      >
        <CardWrapper class="fill-height pa-5">
          <HTMLItem
            :meta="report.meta"
            data-test="bug-event-report"
          />
        </CardWrapper>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTBtn
          :disabled="isExpired || isCompleted"
          data-test="button-done"
          :loading="completeButtonLoading"
          @click="onComplete"
        >
          {{ $t('button_text.done') }}
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import CardWrapper from '@/components/cards/CardWrapper.vue';
import HTMLItem from '@/components/items/HTMLItem.vue';

export default {
  name: 'BugEvent',
  components: { HTMLItem, CardWrapper },
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    completeButtonLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    answer() {
      return this.event?.task?.content[1];
    },
    report() {
      return this.event?.task?.content[0];
    },
    isCompleted() {
      return this.event?.task?.isCompleted;
    },
    isExpired() {
      return this.event?.expired;
    },
  },
  methods: {
    onComplete() {
      this.$emit('complete');
    },
  },
};
</script>

<style scoped>

</style>
