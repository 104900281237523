<template>
  <VContainer
    v-if="event.task"
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <VContainer class="pa-0">
          <VRow dense>
            <VCol
              v-if="event.task.isCompleted"
              cols="auto"
            >
              <ChipStatus
                :status="$options.COMPLETED"
                :item-type="$options.chipStatusTypes.EVENT"
                show-icon
              />
            </VCol>
            <VCol
              v-if="event.task.description"
              cols="auto"
            >
              <ChipDate
                :date="event.task.description"
              />
            </VCol>
            <VCol
              v-if="!event.task.isCompleted && event.task.durationMin"
              cols="auto"
            >
              <ChipDuration
                :duration="event.task.durationMin"
              />
            </VCol>
            <VCol
              v-if="event.task.maxBonus"
              cols="auto"
            >
              <ChipBonus
                :max="event.task.maxBonus"
                :current="event.task.bonus"
              />
            </VCol>
          </VRow>
          <VRow dense>
            <VCol>
              <template v-if="isBug">
                <BugEvent
                  :event="event"
                  :complete-button-loading="completeBtnLoading"
                  @complete="completeTask"
                />
              </template>
              <template v-if="isPool">
                <QuestionnaireEvent
                  :event="event"
                  :complete-button-loading="completeBtnLoading"
                  @complete="completeTask"
                />
              </template>
              <template v-if="isCheckpoint">
                <CheckpointEvent
                  :event="event"
                  :complete-button-loading="completeBtnLoading"
                  @complete="completeTask"
                />
              </template>
            </VCol>
          </VRow>
        </VContainer>
      </VCol>
      <Portal
        to="tt-view-right"
      >
        <AskQuestion
          :buttons="['ask']"
          :entity-type="entityType.EVENT"
          :task-id="event.task.id"
          @close="$router.push({name : Names.R_APP_HANDBOOK})"
        />
      </Portal>
    </VRow>
  </VContainer>
</template>

<script>
import { profileEventsGet } from '@/services/api/snami';
import {
  chipStatusTypes, COMPLETED, entityType, ITEM_TYPES, taskType,
} from '@/helpers/constants';
import BugEvent from '@/components/events/BugEvent.vue';
import QuestionnaireEvent from '@/components/events/QuestionnaireEvent.vue';
import CheckpointEvent from '@/components/events/CheckpointEvent.vue';
import AskQuestion from '@/components/shared/AskQuestion.vue';
import * as snamiApi from '@/services/api/snami';
import { mapActions } from 'vuex';
import { GET_EVENTS_ACTION, SET_HAPPY_STATE_STATES_ACTION } from '@/plugins/vuex/actionTypes';
import ChipDuration from '@/components/chips/ChipDuration.vue';
import ChipBonus from '@/components/chips/ChipBonus.vue';
import ChipDate from '@/components/chips/ChipDate.vue';
import ChipStatus from '@/components/chips/ChipStatus.vue';

export default {
  name: 'EventView',
  ITEM_TYPES,
  COMPLETED,
  chipStatusTypes,
  components: {
    ChipStatus,
    ChipDate,
    ChipBonus,
    ChipDuration,
    AskQuestion,
    CheckpointEvent,
    QuestionnaireEvent,
    BugEvent,
  },
  inject: ['Names'],
  data() {
    return {
      entityType,
      event: {},
      loading: false,
      completeBtnLoading: false,
    };
  },
  computed: {
    isBug() {
      if (!this.event?.task?.type) {
        return false;
      }
      return this.event.task.type === taskType.BUG;
    },
    isPool() {
      if (!this.event?.task?.type) {
        return false;
      }
      return this.event.task.type === taskType.POOL;
    },
    isCheckpoint() {
      if (!this.event?.task?.type) {
        return false;
      }
      return this.event.task.type === taskType.CHECKPOINT;
    },
  },
  watch: {
    '$route.params.eventId': {
      immediate: true,
      async handler() {
        await this.init();
      },
    },
  },
  methods: {
    ...mapActions('happyState', { setHappyStateStatesAction: SET_HAPPY_STATE_STATES_ACTION }),
    ...mapActions('handbook', { getEventsAction: GET_EVENTS_ACTION }),
    async completeTask() {
      try {
        this.completeBtnLoading = true;
        const { data: { happyState } } = await snamiApi.taskComplete({
          data: {
            checkOnly: false,
            taskId: this.event.task.id,
            entityType: entityType.EVENT,
          },
        });
        this.setHappyStateStatesAction({ states: happyState });
        await this.init();
      } catch (e) {
        console.warn(e);
      } finally {
        this.completeBtnLoading = false;
      }
    },
    async init() {
      try {
        this.loading = true;
        await this.getEventData({ include_inactive: true });
      } catch (e) {
        // TODO Нужно добавить обработку ошибок для страницы, уведомление или что-нибудь еще
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },
    async getEventData(dataParams = {}) {
      try {
        const { data } = await profileEventsGet({ data: { event_id: this.$route.params.eventId, ...dataParams } });
        if (!data.length) {
          this.$router.push({ name: this.Names.R_APP_HANDBOOK });
          return;
        }
        this.event = data.map((item) => ({
          ...item,
          task: {
            ...item.task,
            content: item.task.content.map((contentItem) => ({
              ...contentItem,
              meta: JSON.parse(contentItem.meta),
            })),
          },
        })).shift();

        const setShownPromises = this.event.task.content
          .filter((item) => !item.firstShown)
          .map((taskContent) => this.setTaskContentShown(taskContent.id, this.event.task.id));

        if (setShownPromises.length) {
          await Promise.all(setShownPromises);
        }
        await this.getEventsAction({ data: { include_inactive: true } });
      } catch (e) {
        this.$router.push({ name: this.Names.R_APP_HANDBOOK });
        console.warn(e);
      }
    },
    async setTaskContentShown(contentTaskId, taskId) {
      try {
        await snamiApi.setShown({
          data: {
            contentTaskId,
            entityType: entityType.EVENT,
            taskId,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>

</style>
